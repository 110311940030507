import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Differences between Day and Night Motion Detection Mode",
  "path": "/Frequently_Asked_Question/WQHD_Motion_Detection_Day_Night_Mode/",
  "dateChanged": "2022-09-29",
  "author": "Mike Polinowski",
  "excerpt": "I am getting too many false alarms during the night with my IN-9408 2k+ WQHD. But there is no issue during the day. What am I doing wrong?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Differences between Day and Night Motion Detection Mode' dateChanged='2022-09-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_Motion_Detection_Day_Night_Mode/' locationFR='/fr/Frequently_Asked_Question/WQHD_Motion_Detection_Day_Night_Mode/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "differences-between-day-and-night-motion-detection-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#differences-between-day-and-night-motion-detection-mode",
        "aria-label": "differences between day and night motion detection mode permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Differences between Day and Night Motion Detection Mode`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#day--colour-mode"
        }}>{`Day / Colour Mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#night--monochrome-mode"
        }}>{`Night / Monochrome Mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#recommendation"
        }}>{`Recommendation`}</a></li>
    </ul>
    {/* /TOC */}
    <p><strong parentName="p">{`Q`}</strong>{`: I am getting too many false alarms during the night with my IN-9408 2k+ WQHD. But there is no issue during the day. What am I doing wrong?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: All WQHD models have to different `}<strong parentName="p">{`Motion Detection Modes`}</strong>{` - the daylight/artificial light colour mode and a night mode that is optimized for detecting moving high-contrast edges in monochromatic images.`}</p>
    <h2 {...{
      "id": "day--colour-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#day--colour-mode",
        "aria-label": "day  colour mode permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Day / Colour Mode`}</h2>
    <p>{`When your camera runs in colour mode the software motion detection (those are the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{`) is optimized to detect `}<strong parentName="p">{`changes in colour`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/c7f8638ce94603cacb0b91e54b56107d/WQHD_Motion_Detection_Areas_Day_Night_Mode_01.gif",
        "alt": "WQHD Cameras and Motion Detection Areas Day & Night Mode"
      }}></img></p>
    <p>{`As you can see in the example above the motion detection algorithm primarily reacts to the coloured interior of each square. Changes in light intensity might change the colour value of a pixel - but the alarm detection due to light changes is largely suppressed as changes in light condition - moving shadows, light reflections, etc. - are a primary cause for false alerts.`}</p>
    <h2 {...{
      "id": "night--monochrome-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#night--monochrome-mode",
        "aria-label": "night  monochrome mode permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Night / Monochrome Mode`}</h2>
    <p>{`When your camera runs in black&white mode the software motion detection (those are the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Areas/"
      }}>{`Alarm Areas`}</a>{`) is optimized to detect `}<strong parentName="p">{`changes in light intensity`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/72b7fb45458b2151a4afea3a226257d7/WQHD_Motion_Detection_Areas_Day_Night_Mode_02.gif",
        "alt": "WQHD Cameras and Motion Detection Areas Day & Night Mode"
      }}></img></p>
    <p>{`As you can see in the example above the motion detection algorithm primarily reacts to the movement of the high contrast edges around each square. The brightness of the coloured interior is mostly constant and ignored by the algorithm.`}</p>
    <h2 {...{
      "id": "recommendation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#recommendation",
        "aria-label": "recommendation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recommendation`}</h2>
    <p>{`Due to the different detection modes for day and night you might want to use different settings for your detection areas. Go to `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Alarm/Schedule/"
      }}>{`Alarm/Shedule`}</a>{` and split the alarm areas into two sets - one for night, the other for day mode and adjust the sensitivity of each set accordingly:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f997b09f3375887f8b8d0f0c8d605695/bb3ba/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACDUlEQVQoz1WRXW/SYBiG+Q+U0FHAhG1txlu2BCQ7cWNAZ9sVKH37TTvmUCebpRTcjHGILBvEODLPdoITE+fUuGiixvNhPPBfKcwFvXPnOrvy5M7jikQigCRniCmqtE9vv01ZvcRWb3Hr5eKYvaX7J0m7v1zt5x69y93aATMEOjERCoVcAABAkvj0JLz9sPb8zG73rYNX1v5J5Yp2u3+3cSxZHbnSWd0+SnLKtWDA7/cHg8GRDABOEFn2Zt3aqJRLtrVZc5yaU3Vsu+44D2q18r0NSYSSJKqyNBcBbgTBMMzv91/JOE4zrKzqfJaD5Za890VqfpSefpKa53LzXG19Ng6+FtrfV/c+ROPziNuNouh/MsuyxeKaIub1+pH54pd5eGF2f5jdgdkdGN1B4fBC7/40O99i8XkEQbxer8/nc4XDYZIkCYJgGKZgGKLAK+WG3nyv7b7Rdk9HHFZvnGpPzszG61h83uNBUBTFMOyvPLrMFE2joGu6piqyBGEeCvywMA+hkM1wPLcCFSMau+7xeMYyAIDA8WWayYgFTlAVVYWiKMBhBCgKfFbI0BSVWmDZFLcSjUbH8uXmyWlC4enjHfGZldcVSZIVeVhVFqFaqmq17vp6tZ0TWrIWj8Xc/24GAEzhBJO68XgtXVETNJVOpS9DpZNLVFaldDuT0TYXEneS6bnZWQzDAoHAnz//BksOujL2APDEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f997b09f3375887f8b8d0f0c8d605695/e4706/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.avif 230w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/d1af7/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.avif 460w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/7f308/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.avif 920w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/36c14/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.avif 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f997b09f3375887f8b8d0f0c8d605695/a0b58/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.webp 230w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/bc10c/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.webp 460w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/966d8/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.webp 920w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/eb6f0/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.webp 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f997b09f3375887f8b8d0f0c8d605695/81c8e/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png 230w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/08a84/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png 460w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/c0255/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png 920w", "/en/static/f997b09f3375887f8b8d0f0c8d605695/bb3ba/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png 1121w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f997b09f3375887f8b8d0f0c8d605695/c0255/WQHD_Motion_Detection_Areas_Day_Night_Mode_03.png",
              "alt": "WQHD Cameras and Motion Detection Areas Day & Night Mode",
              "title": "WQHD Cameras and Motion Detection Areas Day & Night Mode",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      